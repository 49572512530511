import Address from '@/models/Address';

export default class AddressRepository {
    public static getAll() {
        return Address.all();
    }

    public static getById(id: string | null) {
        if (id == null) {
            return null;
        }
        return Address.query().whereId(id).withAll().first();
    }
}
