
import { Component, Prop, Vue } from 'vue-property-decorator';
import AddressRepository from '@/repositories/AddressRepository';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import Country from '@/models/Country';
import CountryRepository from '@/repositories/CountryRepository';
import { Button, Divider, Form, Input, Select } from 'ant-design-vue';
import Textarea from 'primevue/textarea/Textarea';
import { validateAndSubmitAddress } from '@/helpers/project/AddressHelper';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { AxiosError } from 'axios';

@Component({
    name: 'AddressFormNew',
    components: { Form, FormItem: Form.Item, Select, SelectOption: Select.Option, Textarea, Input, Divider, Button },
})
export default class AddressFormNew extends Vue {
    @Prop({ default: null }) private addressId!: string | null;
    @Prop({ required: true }) private parentRelationshipName!: string;
    @Prop({ required: true }) private parentRelationshipPayload!: object | null;
    @Prop({ default: false }) private shouldShowSubmitButton!: boolean;

    private get form() {
        return this.$form.createForm(this, {
            name: 'addressForm',
        } as IformCreateOption);
    }

    private get countries() {
        return CountryRepository.getAll() || [];
    }

    private get defaultCountry() {
        const userGroup = UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
        if (CountryRepository.getById(userGroup?.clientCountryId.id as string)) {
            return CountryRepository.getById(userGroup?.clientCountryId.id as string);
        } else {
            return CountryRepository.getByCode('SI');
        }
    }
    private get address() {
        return AddressRepository.getById(this.addressId);
    }

    public async validateAndSubmit(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        try {
            await validateAndSubmitAddress({
                parentRelationshipPayload: this.parentRelationshipPayload,
                parentRelationshipName: this.parentRelationshipName,
                addressId: this.addressId,
                form: this.form,
            });
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return Promise.reject(e);
        }

        this.$notification.success({
            message: this.$t('Promjene uspješne!') as string,
            description: '',
        });

        return Promise.resolve();
    }

    private mounted() {
        Country.getAll();
    }
}
