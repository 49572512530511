var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Form',{staticClass:"wrapperAddressForm",attrs:{"colon":false,"form":_vm.form},on:{"submit":_vm.validateAndSubmit}},[_c('div',{staticClass:"row"},[_c('FormItem',{attrs:{"label":_vm.$t('Kontakt osoba')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'name',
                    {
                        initialValue: _vm.address ? _vm.address.name : '',
                        rules: [{ required: true, message: _vm.$t('Kontakt osoba je obavezna!') }],
                    },
                ]),expression:"[\n                    'name',\n                    {\n                        initialValue: address ? address.name : '',\n                        rules: [{ required: true, message: $t('Kontakt osoba je obavezna!') }],\n                    },\n                ]"}],attrs:{"data-test":"addressName"}})],1)],1),_c('Divider',{staticClass:"u-b2--bold divider",attrs:{"dashed":true,"orientation":"left"}},[_vm._v(_vm._s(_vm.$t('Adresa i telefonski broj'))+" ")]),_c('div',{staticClass:"row row--large"},[_c('FormItem',{attrs:{"label":_vm.$t('Ulica i ku\u0107ni broj *')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'streetNameAndNumber',
                    {
                        initialValue: _vm.address ? _vm.address.streetNameAndNumber : '',
                        rules: [{ required: true, message: _vm.$t('Ulica i kućni broj su obavezni!') }],
                    },
                ]),expression:"[\n                    'streetNameAndNumber',\n                    {\n                        initialValue: address ? address.streetNameAndNumber : '',\n                        rules: [{ required: true, message: $t('Ulica i kućni broj su obavezni!') }],\n                    },\n                ]"}],attrs:{"data-test":"addressStreetNameAndNumber"}})],1),_c('FormItem',{attrs:{"label":_vm.$t('Zgrada')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'building',
                    {
                        initialValue: _vm.address ? _vm.address.building : '',
                    },
                ]),expression:"[\n                    'building',\n                    {\n                        initialValue: address ? address.building : '',\n                    },\n                ]"}],attrs:{"data-test":"addressBuilding"}})],1)],1),_c('div',{staticClass:"row"},[_c('FormItem',{attrs:{"label":_vm.$t('Po\u0161tanski broj *')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'poNumber',
                    {
                        initialValue: _vm.address ? _vm.address.poNumber : '',
                        rules: [{ required: true, message: _vm.$t('Poštanski broj je obavezan!') }],
                    },
                ]),expression:"[\n                    'poNumber',\n                    {\n                        initialValue: address ? address.poNumber : '',\n                        rules: [{ required: true, message: $t('Poštanski broj je obavezan!') }],\n                    },\n                ]"}],attrs:{"data-test":"addressPoNumber"}})],1),_c('FormItem',{attrs:{"label":_vm.$t('Grad *')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'city',
                    {
                        initialValue: _vm.address ? _vm.address.city : '',
                        rules: [{ required: true, message: _vm.$t('Grad je obavezan') }],
                    },
                ]),expression:"[\n                    'city',\n                    {\n                        initialValue: address ? address.city : '',\n                        rules: [{ required: true, message: $t('Grad je obavezan') }],\n                    },\n                ]"}],attrs:{"data-test":"addressCity"}})],1),(_vm.defaultCountry)?_c('FormItem',{attrs:{"label":_vm.$t('Dr\u017eava *')}},[_c('Select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'country',
                    {
                        initialValue: _vm.address && _vm.address.country ? _vm.address.country.id : _vm.defaultCountry.id,
                        rules: [{ required: true, message: 'Država je obavezna!' }],
                    },
                ]),expression:"[\n                    'country',\n                    {\n                        initialValue: address && address.country ? address.country.id : defaultCountry.id,\n                        rules: [{ required: true, message: 'Država je obavezna!' }],\n                    },\n                ]"}],attrs:{"placeholder":_vm.$t('Odaberi dr\u017eavu'),"data-test":"addressCountry","filterOption":"","optionFilterProp":"children","show-search":""}},_vm._l((_vm.countries),function(country){return _c('SelectOption',{key:country.id,staticClass:"u-b1",attrs:{"data-test":country.name,"value":country.id}},[_vm._v(" "+_vm._s(country.name)+" ")])}),1)],1):_vm._e()],1),_c('div',{staticClass:"row row--small"},[_c('FormItem',{attrs:{"label":_vm.$t('Telefon')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phone',
                    {
                        initialValue: _vm.address ? _vm.address.phone : '',
                    },
                ]),expression:"[\n                    'phone',\n                    {\n                        initialValue: address ? address.phone : '',\n                    },\n                ]"}],attrs:{"data-test":"addressPhone"}})],1)],1),_c('div',{staticClass:"row"},[_c('FormItem',{attrs:{"label":_vm.$t('Poruka')}},[_c('Textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'note',
                    {
                        initialValue: _vm.address ? _vm.address.note : '',
                    },
                ]),expression:"[\n                    'note',\n                    {\n                        initialValue: address ? address.note : '',\n                    },\n                ]"}],staticClass:"textarea u-b0-",attrs:{"data-test":"addressNote"}})],1)],1),(_vm.shouldShowSubmitButton)?_c('FormItem',[_c('Button',{attrs:{"data-test":"submit","html-type":"submit","type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Dodaj adresu'))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }