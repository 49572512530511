import { render, staticRenderFns } from "./GenericDeletePopconfirm.vue?vue&type=template&id=6f48e4db&scoped=true"
import script from "./GenericDeletePopconfirm.vue?vue&type=script&lang=ts"
export * from "./GenericDeletePopconfirm.vue?vue&type=script&lang=ts"
import style0 from "./GenericDeletePopconfirm.vue?vue&type=style&index=0&id=6f48e4db&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f48e4db",
  null
  
)

export default component.exports