
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import AddressRepository from '@/repositories/AddressRepository';
import { formatAddress } from '@/helpers/project/AddressHelper';
import Address from '@/models/Address';

@Component({
    name: 'AddressDetails',
    components: { TwoRowWithTag },
})
export default class AddressDetails extends Vue {
    @Prop({ default: null }) private addressId!: string | null;

    private get address() {
        return AddressRepository.getById(this.addressId as string) as Address;
    }

    private get phone() {
        return this.address.phone || '-';
    }

    private get note() {
        return this.address.note || '-';
    }

    private formattedAddress() {
        if(this.address) {
            return formatAddress(this.address);
        }
    }
}
