
import { Component, Prop, Vue } from 'vue-property-decorator';
import AddressDetails from '@/components/views/NewProjectView/AddressDetails.vue';
import AdditionalAddressTopBar from '@/components/views/NewProjectView/Lead/AdditionalAddressTopBar.vue';
import { Modal } from 'ant-design-vue';
import AddressFormNew from '@/components/views/NewProjectView/Lead/AddressFormNew.vue';
import { ModalWidth } from '@/enums/global/ModalWidth';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'AdditionalAddress',
    components: { AddressFormNew, AddressDetails, AdditionalAddressTopBar, Modal },
})
export default class AdditionalAddress extends Vue {
    @Prop({ required: true }) private parentRelationshipName!: string;
    @Prop({ required: true }) private parentRelationshipPayload!: object | null;
    @Prop({ required: true }) private parentRelationshipId!: string;
    @Prop({ default: null }) private addressId!: string | null;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ default: null }) private emptyTitle!: string | null;
    @Prop({ default: null }) private filledTitle!: string | null;

    private isModalVisible = false;
    private modalWidth = ModalWidth;
    private isLoading = false;

    private onModalCancel() {
        this.isModalVisible = false;
    }

    private async onModalConfirm() {
        this.isLoading = true;

        try {
            await (this.$refs.addressForm as AddressFormNew).validateAndSubmit();
            EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
            EventBus.$emit(EventBusEvents.fetchProjectFromRepository);
        } catch (e) {
            return;
        } finally {
            this.isLoading = false;
        }

        this.isModalVisible = false;
    }
}
